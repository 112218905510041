import { Button } from "@essentials/default-styles";
import { CalendarIcon } from "@essentials/icons";
import styled from "styled-components";
import { SessionsRouterPaths } from "../../common/router/SessionsRouterPaths";
import { useTranslation } from "react-i18next";
import { CustomCalendar } from "../../components/CustomCalendar";
import { useAppRouter } from "../../helpers/hooks/useAppRouter";

interface ISchedulePageProps {
    className?: string;
}

const SchedulePage = styled((props: ISchedulePageProps) => {
    const { redirect } = useAppRouter();
    const { t } = useTranslation("schedule");
    return (
        <div className={`${props.className} defaultPage pageAnimation`}>
            <div className={"pageHeader"}>
                <h2>
                    <span>
                        <CalendarIcon />
                        {t("title")}
                    </span>
                </h2>
                <Button onClick={() => redirect(SessionsRouterPaths.DefaultPath)}>Minha Agenda</Button>
            </div>
            <div className={"pageContent"}>
                <CustomCalendar />
            </div>
        </div>
    )
})`
    padding-top: 64px!important;
    .pageHeader {
        margin-top: 48px;
        border-bottom: 1px solid ${props => props.theme.colors.border};
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        h2 {
            margin: 0;
            span {
                display: flex;
                align-items: center;
                padding-bottom: 10px;
                position: relative;
                font-size: 24px;
                font-weight: 500;
                
                svg {
                    color: ${props => props.theme.colors.secondary};
                    width: 24px;
                    margin-right: 10px;
                }
                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: ${props => props.theme.colors.secondary};
                }
            }
        }
    }
`;

export default SchedulePage;