import { Loader } from "@essentials/default-styles";
import styled from "styled-components";

interface IRelativeLoadingProps {
    className?: string;
    style?: React.CSSProperties;
    transparency?: boolean;
    show?: boolean;
    fixed?: boolean;
}

export const RelativeLoading = styled((props: IRelativeLoadingProps) => {
    return (
        <div style={props?.style} className={`${props.className} ${props.show ? "showRelativeLoading" : ""}`}>
            <Loader style={{zIndex: 1}} />
        </div>
    );
})`
    align-items: center;
    background: inherit;
    border-radius: 4px;
    display: flex !important;
    justify-content: center;
    position: ${props => props.fixed ? "fixed" : "absolute"};
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${props => props.fixed ? "100vh" : "100%"};
    z-index: ${props => props.fixed ? 1005 : 1};
    background: transparent!important;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${props => props.theme.colors.greyLight};
        opacity: ${props => props.transparency ? .6 : 1};
    }
    &.showRelativeLoading {
        opacity: 1;
        pointer-events: unset;
    }
    @keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`