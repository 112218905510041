import { useEffect, createContext, useState } from 'react';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { loginRequest } from '../configs/authConfigs';
import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useAppRouter } from '../helpers/hooks/useAppRouter';
import { SessionsRouterPaths } from '../common/router/SessionsRouterPaths';
import { useUserBusiness } from '../business/base/useUserBusiness';
import { IUser } from '../interfaces/base/IUser';
import { RelativeLoading } from '../components/RelativeLoading/RelativeLoading';
import { TopBar } from '../components/TopBar/TopBar';
import styled from 'styled-components';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

interface IAuthenticationContext {
    user?: IUser;
    msAccount: AccountInfo | null;
    authenticating: boolean;
    refreshToken: () => Promise<string>;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
    user: undefined,
    msAccount: null,
    authenticating: true,
    refreshToken: () => Promise.resolve("")
});

interface IAuthenticationContextAPIprops {
    children?: any;
    className?: string;
}

export const AuthenticationContextAPI = styled((props: IAuthenticationContextAPIprops) => {
    const [user, setUser] = useState<IUser | undefined>(undefined);
    const { login, error, acquireToken } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const { inProgress, instance } = useMsal();
    const { redirect } = useAppRouter();

    const { getUserMe } = useUserBusiness();
    const [userError, setUserError] = useState(false);

    const successCallback = () => {
        acquireToken(InteractionType.Silent)
            .then(async (authResult: AuthenticationResult | null) => {
                try {
                    const resultUser = await getUserMe(authResult?.accessToken);
                    setUser(resultUser);

                    if (resultUser)
                        initializeGTM(resultUser);
                } catch {
                    setUserError(true);
                }
            });
    };

    const initializeGTM = (user: IUser) => {
        if (process.env.REACT_APP_TAG_MANAGER_ID) {
            console.log("TAG FOUNDED, initializing");
            TagManager.dataLayer({
                dataLayer: {
                    userId: user.id,
                    userMail: user.mail,
                    isExpert: true
                }
            });

            const tagManagerArgs: TagManagerArgs = {
                gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
            };
            TagManager.initialize(tagManagerArgs);
        }
    };

    useEffect(() => {
        // Default to using the first account if no account is active on page load
        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            instance.setActiveAccount(instance.getAllAccounts()[0]);
        }
    }, [inProgress]);

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError)
            login(InteractionType.Redirect, loginRequest)
                .then(successCallback)
                .catch(err => console.log(err));
    }, [error]);

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            successCallback();
            redirect(SessionsRouterPaths.DefaultPath);
        }
    }, [inProgress]);

    const refreshToken = () => new Promise<string>((resolve, reject) => {
        acquireToken(InteractionType.Silent)
            .then((authResult) => {
                resolve(authResult?.accessToken ?? "");
            }).catch(() => reject());
    });

    return (
        <div className={props.className}>
            <AuthenticationContext.Provider value={{ user, msAccount: instance.getActiveAccount(), authenticating: inProgress !== InteractionStatus.None, refreshToken }}>
                <TopBar />
                {
                    !user ?
                        userError ?
                            <p className={"message pageAnimation"}>Esta área é reservada apenas para especialistas do Helpfy</p>
                            : <RelativeLoading transparency show /> :
                        props.children
                }
            </AuthenticationContext.Provider>
        </div>
    )
})`
    .message {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.theme.colors.font};
        padding: 20px;
        text-align: center;
    }
`;