import { useEffect, useMemo, useState } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

export function useAppRouter() {
    const navigate = useNavigate();
    const redirect = (path: To | number, options?: NavigateOptions | undefined) => {
        if (typeof path === "number")
            navigate(path);
        else
            navigate(path, options);
    };


    return {
        redirect
    }
}