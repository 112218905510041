import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SessionsRouterPaths } from "../../common/router/SessionsRouterPaths";
import { AuthenticationContext } from "../../contextAPIs/AuthenticationContext";
import { useAppRouter } from "../../helpers/hooks/useAppRouter";
import { MsalAccounts } from "../MsalAccounts/MsalAccounts";
import helpfyLogo from '../../helpers/imgs/helpfyLogo.png';

interface ITopBarProps {
    className?: string;
}

export const TopBar = styled((props: ITopBarProps) => {
    const { t } = useTranslation("topBar");
    const { redirect } = useAppRouter();

    const { user } = useContext(AuthenticationContext);

    return (
        <div className={`${props.className}`}>
            <div className={"wrapper"}>
                <div className={"titleAndActions"}>
                    <div className={"brand"}>
                        <img src={helpfyLogo} />
                        <h2>{t("title")}</h2>
                    </div>
                    <AuthenticatedTemplate>
                        {
                            !!user &&
                            <div className={"actions"}>
                                <button className={`${window.location.pathname === SessionsRouterPaths.DefaultPath ? "active" : ""}`} onClick={() => redirect(SessionsRouterPaths.DefaultPath)} >{t("calendarBtn")}</button>
                                <button className={`${window.location.pathname === SessionsRouterPaths.PendingEvaluation ? "active" : ""}`} onClick={() => redirect(SessionsRouterPaths.PendingEvaluation)}>{t("evaluationBtn")}</button>
                            </div>
                        }
                    </AuthenticatedTemplate>
                </div>
                <MsalAccounts />
            </div>
        </div>
    );
})`
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1006;
    width: 100%;
    background: ${props => props.theme.colors.white};
    box-shadow: 0 0px 10px ${props => props.theme.colors.shadow};
    .wrapper {
        margin: 0 auto;
        max-width: 1368px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;
        height: 100%;
        .titleAndActions {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            height: 100%;
            .brand {
                display: flex;
                align-items: center;
                img {
                    margin-right: clamp(15px, 3vw, 30px);
                    width: clamp(32px, 5vw, 48px);
                    border-radius: 5px;
                }
                h2 {
                    margin: 0;
                    font-size: clamp(18px, 3vw, 24px);
                    font-weight: bold;
                    color: ${props => props.theme.colors.font};
                }
            }
            .actions {
                margin-left: 20px;
                height: 100%;
                display: flex;
                flex-wrap: nowrap;
                button {
                    border: none;
                    background: none;
                    padding: 0 10px;
                    height: 100%;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 500;
                    position: relative;
                    &:hover {
                        background: ${props => props.theme.colors.grey};
                    }
                    &.active {
                        &:after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 3px;
                            background: ${props => props.theme.colors.secondary};
                        }
                    }
                }
            }
        }
        @media screen and (max-width: ${props => props.theme.windowSize.mobileLimit}px) {
            .titleAndActions {
                h2 {
                    display: none;
                }
            }
        }
    }
`;