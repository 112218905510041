import { useEffect, useImperativeHandle, useRef, useState } from "react";
import styled from "styled-components";
import { RelativeLoading } from "../RelativeLoading/RelativeLoading";
import { useCalendarBusiness } from "../../business/app/useCalendarBusiness";
import { useData } from "@essentials/helpers";
import { CustomCalendarModal } from "./Modal";
import { addMinutes } from "date-fns";
import { TrashIcon, BlockIcon, ArrowRightIcon, CloseIcon } from "@essentials/icons";
import { Button, FilledButton, Modal } from "@essentials/default-styles";

export interface ICustomCalendarProps {
    className?: string;
}

export const CustomCalendar = styled(function (props: ICustomCalendarProps) {
    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollHeaderRef = useRef<HTMLDivElement>(null);

    const { getMyCalendars, addCalendar, addMultipleCalendars, removeCalendar, clearCalendars } = useCalendarBusiness();
    const [savingCalendars, setSavingCalendars] = useState(false);
    const [calendars, loadingCalendars, reloadCalendars] = useData(getMyCalendars, [], []);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedSlots, setSelectedSlots] = useState<Date[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [nextDays, setNextDays] = useState<Date[][][]>();
    const [hoverColumn, setHoverColumn] = useState<number>();
    const [hoverRow, setHoverRow] = useState<number>();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [clearingCalendars, setClearingCalendars] = useState(false);

    const scrollRight = () => {
        scrollRef.current?.scrollTo({ left: scrollRef.current.scrollLeft + 500, behavior: 'smooth' })
    };

    const scrollLeft = () => {
        scrollRef.current?.scrollTo({ left: scrollRef.current.scrollLeft - 500, behavior: 'smooth' })
    };

    const removeAllCalendars = () => {
        setClearingCalendars(true);
        clearCalendars()
            .then(() => {
                console.log('Calendars cleared')
                reloadCalendars();
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setConfirmationModalOpen(false);
                setClearingCalendars(false);
            })
    };

    function generateDatesForNextXDays(days: number): Date[][][] {
        const result: Date[][][] = [];
        const startDate = new Date(); // Starting from the current date and time
        startDate.setMinutes(0, 0, 0); // Reset minutes, seconds, and milliseconds for a clean start

        for (let day = 0; day < days; day++) {
            const dayEntries: Date[][] = [];
            const currentDate = new Date(startDate.getTime() + day * 86400000); // 86400000 ms per day

            for (let hour = 0; hour < 24; hour++) {
                const hourEntry: Date[] = [
                    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hour, 0),
                    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hour, 30)
                ];
                dayEntries.push(hourEntry);
            }

            result.push(dayEntries);
        }

        return result;
    };

    const initiateDates = (): void => {
        const datesArray = generateDatesForNextXDays(60);
        setNextDays(datesArray);
    };

    function addIndividualDate(date: Date) {
        setSelectedSlots([date, date]);
        setIsModalOpen(true);
    };

    async function deleteSlot(id?: number, date?: Date) {
        if (id && date) {
            await removeCalendar(id, date);
            reloadCalendars();
            setSelectedSlots([]);
        }
    };

    function setHover(column: number, row: number) {
        setHoverColumn(column);
        setHoverRow(row);
    };

    function isToday(date: Date): boolean {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    function isCurrentHour(currentHour: number): boolean {
        const now = new Date();
        return now.getHours() === currentHour;
    };

    function getDayOfWeek(date: Date): string {
        const daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
        return daysOfWeek[date.getDay()];
    };

    function getHour(date: Date): string {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}h`;
    };

    function getHourFromNumber(hour: number): string {
        const hours = hour.toString().padStart(2, '0');
        return `${hours}h`;
    };

    const isSlotSelected = (day: Date) => {
        return selectedSlots.filter(x =>
            x.getTime() === day.getTime()
        ).length > 0
    };

    const getSlotAvailable = (day: Date) => { // hora e hora e meia
        const filteredSlots = calendars.filter(x =>
            x.availableAtFrom && x.availableAtTo &&
            x.reserved == false &&
            x.availableAtFrom.getTime() <= day.getTime() &&
            x.availableAtTo.getTime() > day.getTime()
        );

        if (filteredSlots && filteredSlots.length > 0) {
            return filteredSlots[0];
        }
    };

    const getSlotReserved = (day: Date) => {
        const halfHour = 30 * 60 * 1000; // 30 minutos em milissegundos
        const dayPlusHalfHour = new Date(day.getTime() + halfHour);

        const filteredSlots = calendars.filter(x =>
            x.availableAtFrom && x.availableAtTo &&
            x.reserved === true &&
            (
                (x.availableAtFrom.getTime() <= day.getTime() && x.availableAtTo.getTime() > day.getTime()) ||
                (x.availableAtFrom.getTime() < dayPlusHalfHour.getTime() && x.availableAtTo.getTime() >= dayPlusHalfHour.getTime()) ||
                (x.availableAtFrom.getTime() >= day.getTime() && x.availableAtTo.getTime() <= dayPlusHalfHour.getTime())
            )
        );

        if (filteredSlots.length > 0) {
            return filteredSlots[0];
        }
    };

    const handleMouseDown = (date: Date, event: React.MouseEvent) => {
        if (event.button === 0) {
            setSelectedSlots([date]);
            setIsDragging(true);
        }
    };

    function generateTimeSlots(startDate: Date, endDate: Date): Date[] {
        console.log("Gerando slots entre", startDate, endDate)
        const timeSlots: Date[] = [];
        let currentDate = new Date(startDate);

        while (currentDate < endDate) {
            var d = new Date(currentDate);
            timeSlots.push(d);
            d = addMinutes(d, 30);
            timeSlots.push(d);
            currentDate.setHours(currentDate.getHours() + 1);
        }

        return timeSlots;
    };

    const handleMouseEnter = (date: Date) => {
        if (isDragging && !selectedSlots.includes(date)) {
            // Verificar se a data corrente é do mesmo dia que as datas em selectedSlots
            const sameDay = selectedSlots.some(slot => slot.getDate() === date.getDate() &&
                slot.getMonth() === date.getMonth() &&
                slot.getFullYear() === date.getFullYear());

            if (!sameDay) {
                // Se a data corrente não for do mesmo dia, ignorar
                return;
            }

            let newSelectedSlots = [...selectedSlots, date].sort((a, b) => a.getTime() - b.getTime());

            let firstDate = newSelectedSlots[0];
            let currentDate = date;

            if (firstDate.getDate() === currentDate.getDate() &&
                firstDate.getMonth() === currentDate.getMonth() &&
                firstDate.getFullYear() === currentDate.getFullYear()) {
                setSelectedSlots(newSelectedSlots);
            }

            if (newSelectedSlots.length > 1) {
                let lastDate = newSelectedSlots[newSelectedSlots.length - 2];
                const differenceInMilliseconds = currentDate.getTime() - lastDate.getTime();
                const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

                if (differenceInMinutes > 30) {
                    newSelectedSlots = [...newSelectedSlots, ...generateTimeSlots(lastDate, currentDate)];
                }

                // Adicionado para lidar com a seleção de datas anteriores
                let firstSelectedDate = newSelectedSlots[0];
                const reverseDifferenceInMilliseconds = firstSelectedDate.getTime() - currentDate.getTime();
                const reverseDifferenceInMinutes = reverseDifferenceInMilliseconds / (1000 * 60);

                if (reverseDifferenceInMinutes > 30) {
                    newSelectedSlots = [...generateTimeSlots(currentDate, firstSelectedDate), ...newSelectedSlots];
                }
            }

            setSelectedSlots(newSelectedSlots);
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);

            if (selectedSlots.length > 1) {
                setIsModalOpen(true);
            }
        }
    };

    const onScroll = (e: any) => {
        if (scrollHeaderRef.current && scrollRef?.current) {
            scrollHeaderRef.current.scrollLeft = scrollRef?.current?.scrollLeft;
        }
    };

    useEffect(() => {
        initiateDates();
    }, []);

    return (
        <div className={`${props.className}`}>
            <div className="briefing">
                <p>Utilize a agenda abaixo para informar seus horários disponíveis para atendimento no Helpfy.
                    Lembre-se, após um horário ser definido, o mesmo não poderá ser cancelado casa uma reunião já tenha sido agendada neste horário.</p>
            </div>

            <div className="monthControl">
                <div className="nav">
                    <div className="action prev" onClick={scrollLeft}>
                        <ArrowRightIcon />
                    </div>
                    <div className="action next" onClick={scrollRight}>
                        <ArrowRightIcon />
                    </div>
                </div>
                <div className="actions" onClick={() => setConfirmationModalOpen(true)}>
                    <Button><TrashIcon /> Remover tudo</Button>
                </div>
            </div>

            <div className="calendarHeader" ref={scrollHeaderRef}>
                <div className="innerScroll">
                    {
                        nextDays && nextDays?.map((day: Date[][], index: number) => {
                            return (
                                <div key={index} className={`day ${index === hoverColumn ? 'hover' : ''}`}>
                                    <div className={`header ${isToday(day[0][0]) ? 'selected' : ''}`}>
                                        <div className="top">{day[0][0].getDate()}<small>/{day[0][0].getMonth() + 1}</small></div>
                                        <span>{getDayOfWeek(day[0][0])}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="daysList" ref={scrollRef} onScroll={onScroll}>
                {
                    nextDays?.map((day: Date[][], index: number) => {
                        return (
                            <div key={index} className={`day ${index === hoverColumn ? 'hover' : ''}`}>
                                {
                                    day.map((day: Date[], i: number) => {
                                        const firstHalfReserved = getSlotReserved(day[0]);
                                        const lastHalfReserved = getSlotReserved(day[1]);

                                        const firstHalfAvailable = getSlotAvailable(day[0]);
                                        const lastHalfAvailable = getSlotAvailable(day[1]);

                                        const isFirstHalfReserved = firstHalfReserved !== undefined;
                                        const isLastHalfReserved = lastHalfReserved !== undefined;

                                        const isFirstHalfAvailable = firstHalfAvailable !== undefined;
                                        const isLastHalfAvailable = lastHalfAvailable !== undefined;

                                        return (
                                            <div key={i * 10} className={`hour ${i === hoverRow ? 'hover' : ''}`} onMouseEnter={() => setHover(index, i)}>
                                                <div className={`half firstHalf ${isFirstHalfAvailable ? 'available' : ''} ${isSlotSelected(day[0]) ? 'selected' : ''} ${isFirstHalfReserved ? 'reserved' : ''}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        if (!isFirstHalfReserved && firstHalfAvailable) {
                                                            deleteSlot(firstHalfAvailable.id, day[0]);
                                                        }
                                                        else if (!isFirstHalfReserved && !firstHalfAvailable){
                                                            addIndividualDate(day[0])
                                                        }

                                                    }}
                                                    onMouseDown={(event) => handleMouseDown(day[0], event)}
                                                    onMouseUp={() => handleMouseUp()}
                                                    onMouseEnter={() => handleMouseEnter(day[0])}>
                                                    {
                                                        !isFirstHalfReserved && !isFirstHalfAvailable && <p>{getHour(day[0])}</p>
                                                    }
                                                    {
                                                        isFirstHalfReserved && <div className="trash"><BlockIcon /><p>reservado</p></div>
                                                    }
                                                    {
                                                        !isFirstHalfReserved && isFirstHalfAvailable && <div className="trash"><TrashIcon /><p>{getHour(day[1])}</p></div>
                                                    }
                                                </div>
                                                <div className={`half lastHalf ${isLastHalfAvailable ? 'available' : ''} ${isSlotSelected(day[1]) ? 'selected' : ''} ${isLastHalfReserved ? 'reserved' : ''}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (!isLastHalfReserved && lastHalfAvailable) {
                                                            deleteSlot(lastHalfAvailable.id, day[1]);
                                                        }
                                                        else if (!isLastHalfReserved && !lastHalfAvailable){
                                                            addIndividualDate(day[1])
                                                        }

                                                    }}
                                                    onMouseDown={(event) => handleMouseDown(day[1], event)}
                                                    onMouseUp={() => handleMouseUp()}
                                                    onMouseEnter={() => handleMouseEnter(day[1])}>
                                                    {
                                                        !isLastHalfReserved && !isLastHalfAvailable && <p>{getHour(day[1])}</p>
                                                    }
                                                    {
                                                        isLastHalfReserved && <div className="trash"><BlockIcon /><p>reservado</p></div>
                                                    }
                                                    {
                                                        !isLastHalfReserved && isLastHalfAvailable && <div className="trash"><TrashIcon /><p>{getHour(day[1])}</p></div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
            <RelativeLoading style={{ zIndex: 1 }} show={loadingCalendars.status !== "done"} />
            {
                isModalOpen &&
                <CustomCalendarModal
                    startDate={selectedSlots[0]}
                    endDate={selectedSlots[selectedSlots.length - 1]}
                    isModalOpen={isModalOpen}
                    onCloseModal={() => {
                        setSelectedSlots([]);
                        setIsModalOpen(false);
                    }}
                    savingCalendars={savingCalendars}
                    onSave={(initialDate: Date, endDate: Date, repeatType: number, numberOfWeeks: number) => {
                        setSavingCalendars(true);
                        addMultipleCalendars(initialDate, endDate, repeatType, numberOfWeeks)
                            .then(() => {
                                console.log("Calendarios salvos");
                            })
                            .finally(() => {
                                reloadCalendars();
                                setSelectedSlots([]);
                                setIsModalOpen(false);
                                setSavingCalendars(false);
                            });
                    }} />
            }
            {
                confirmationModalOpen &&
                <Modal open={confirmationModalOpen} className={`${props.className}`} style={{ height: 250 }}>
                    <div className={`innerModal`}>
                        <div className={`header`}>
                            <button onClick={() => {
                                setConfirmationModalOpen(false);
                            }}
                                className={"closeBtn"}>
                                <CloseIcon />
                            </button>
                        </div>

                        <div className={`content`}>
                            <h2>Deseja realmente remover toda a disponibilidade?</h2>
                            <br />
                            <Button disabled={clearingCalendars} className="button" style={{width: '100%'}} onClick={() => {
                                setConfirmationModalOpen(false);
                            }}>Cancelar</Button>
                            <FilledButton style={{width: '100%'}} loading={clearingCalendars} disabled={clearingCalendars} onClick={removeAllCalendars}>Sim, remover</FilledButton>
                        </div>
                    </div>
                </Modal>
            }
        </div>

    )
})`
    
    h1,h2,h3 {
        margin: 0px;
    }

    .briefing {
        p {
            color: #3c3c3c;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0px;
        }
    }

    .monthControl {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .nav {
            display: flex;
            justify-content: space-around;

            .action {
                cursor: pointer;
                transition: all .2s ease;
                margin-right: 20px;

                svg {
                    height: 30px;
                    width: 30px;
                }

                &.prev {
                    svg {
                        transform: rotate(180deg);
                        margin-top: 1px;
                    }
                }
            }
        }
    }

    .calendarHeader{
        width: 100%;
        overflow: hidden;

        .innerScroll {
            display: inline-flex;
            flex-direction: row;
        

            .day {
                height: 50px;
                width: 110px!important;
                padding-left: 15px;
                border: 1px solid lightgray;
                display: flex;
                justify-content: center;
                flex-direction: column;

                small {
                    color: #9b9b9b;
                }

                &.selected {
                    border-top: 3px solid #762478 !important;
                }

                p {
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                }

                .top {
                    height: 20px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 20px;
                }
                span {
                    font-size: 12px;
                    color: #535353;
                }
            }
        }

        
        
    }

    .daysList {
        display: flex;
        overflow: scroll;
        height: 55vh;

        .day {
            width: 160px;

            &.hover {
                background: #7624780d;
            }

            &.fixed {
                width: 50px;
                .hour {
                    border:1px solid lightgray; 
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 12px;
                    color: #8d8d8d;
                    font-weight: bold;
                    width: 50px;
                }
                .header {
                    border: none!important;
                }
            }

            

            .headerSpacing {
                margin-top: 50px;
            }

            .hour {
                width: 110px;
                height: 60px;
                display:flex;
                flex-direction: column;
                justify: center;
                align-items: center;
                position: relative;

                .calendarsOverlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: purple;
                    width: 100%;
                    color: white;
                    text-align: center;
                }

                &.selected {
                    border-left: 3px solid #762478 !important;
                }

                &.hover {
                    background: #7624780d;
                }

                .half {
                    border:1px dotted #f1eaf1;
                    height: 50%;
                    width: 100%;
                    cursor: pointer;
                    font-size: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: #cdcdcd;
                        font-size: 12px;
                    }

                    .trash {
                        position: relative;
                        p {
                            margin: 0px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: white;
                            opacity: 1;
                            transition: all .2s ease;
                        }

                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            color: white;
                            transition: all .2s ease;
                            height: 18px;
                            opacity: 0;
                        }
                    }

                    &.selected {
                        background: #7a457b;
                    }

                    &.available {
                        background: #68006a!important;
                        &:hover {
                            filter: brightness(1.2);
                            .trash {
                                svg {
                                    opacity: 1;
                                }
                                p {
                                    opacity: 0;
                                }
                            }
                        }
                    }

                    &.reserved {
                        background: #8f8f8f!important;
                        &:hover {

                            filter: brightness(1.2);

                            .trash {
                                svg {
                                    opacity: 1;
                                }
                                p {
                                    opacity: 0;
                                }
                            }

                            .add svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .innerModal {
        .header {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;

            button {
                background: none;
                border: none;
                padding: 10px 10px 0px 0px;
                cursor: pointer;
                color: gray;
            }
        }

        .content {
            padding: 0px 20px 20px 20px;

            h1,h2,h3 {
                margin: 0px;
                color: #2d2d2d;
                line-height: 30px;
            }

            p {
                margin: 0px;
                margin-bottom: 25px;
            }

            select {
                padding: 5px 10px;
                border: 1px solid darkgray;
                border-radius: 8px;
                margin-bottom: 10px;
                color: #2e2e2e;
            }

            input {
                padding: 5px 10px;
                border: 1px solid darkgray;
                border-radius: 8px;
                margin-bottom: 10px;
                color: #2e2e2e;
                width: 48px;
                text-align: center;
                font-weight: bold;
            }

            button.reserve {
                width: calc(100% - 40px);
                background: #762478;
                color: white;
                font-weight: bold;
                border: none;
                padding: 10px;
                border-radius: 8px;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%);
                cursor: pointer;
            }

            .button {
                margin-top: 20px;
                margin-bottom: 10px;
            }
        }
    }
`;