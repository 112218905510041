import { IRepository } from "../../interfaces/base/IRepository";
import { ICalendar } from "../../interfaces/app/ICalendar";
import { isValid } from "date-fns";
import { normalizeTimezone } from "@essentials/helpers";
import { AuditableEntitiesRepository } from "../base/AuditableEntitiesRepository";
import { dateToDateTimeString } from "@essentials/helpers";

export class CalendarRepository implements IRepository<ICalendar>{
    private objectSet(item: any): ICalendar {
        const auditableRepository = new AuditableEntitiesRepository();
        const auditableEntity = auditableRepository.mapObject(item);

        return {
            availableAtFrom: item.availableAtFrom && isValid(new Date(item.availableAtFrom)) ? normalizeTimezone(new Date(item.availableAtFrom)) : undefined,
            availableAtTo: item.availableAtTo && isValid(new Date(item.availableAtTo)) ? normalizeTimezone(new Date(item.availableAtTo)) : undefined,
            reserved: !!item.reserved,
            ...auditableEntity
        };
    }

    private objectPostSet(item: ICalendar): any {
        return {
            availableAtFrom: item.availableAtFrom ? dateToDateTimeString(item.availableAtFrom) : undefined,
            availableAtTo: item.availableAtTo ? dateToDateTimeString(item.availableAtTo) : undefined,
        };
    }

    mapPostObject(item: ICalendar): any {
        return this.objectPostSet(item);
    }

    mapObject(item: any): (ICalendar | undefined) {
        return item ? this.objectSet(item) : undefined;
    }

    mapObjects(items: any[]): ICalendar[] {
        return items?.length ? items.map(this.objectSet) : [];
    }
};