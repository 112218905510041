import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'pt',
    lng: 'pt',
    resources: {
        en: {
            general: require('./locales/en/general.json'),
            sessions: require('./locales/en/sessions.json'),
            topBar: require('./locales/en/topBar.json'),
            schedule: require('./locales/en/schedule.json')
        },
        pt: {
            general: require('./locales/pt/general.json'),
            sessions: require('./locales/pt/sessions.json'),
            topBar: require('./locales/pt/topBar.json'),
            schedule: require('./locales/pt/schedule.json')
        }
    },
    ns: ['general', 'sessions', 'topBar', 'schedule'],
    defaultNS: 'general'
});

i18n.languages = ['en', 'pt'];

export default i18n;