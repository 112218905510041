import { lazy, Suspense } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { SessionsRouterPaths } from "../common/router/SessionsRouterPaths";
import { RelativeLoading } from "../components/RelativeLoading/RelativeLoading";
import SchedulePage from "../pages/schedule";

const HomePage = lazy(() => import("../pages/sessions/homePage/HomePage"));
// const CalendarModal = lazy(() => import("../pages/sessions/homePage/components/CalendarModal"));
const EvaluationPage = lazy(() => import("../pages/sessions/pendingEvaluation/pendingEvaluation"));

export function AppRouter() {

    return (
        <Routes>
            {/* SESSIONS */}
            <Route path={SessionsRouterPaths.DefaultPath}
                element={
                    <Suspense fallback={<RelativeLoading show fixed transparency />}>
                        <HomePage />
                    </Suspense>
                }
            >
                {/* <Route path={SessionsRouterPaths.CalendarPath} element={
                    <Suspense fallback={<RelativeLoading show fixed transparency />}>
                        <CalendarModal />
                    </Suspense>
                } /> */}
            </Route>

            <Route path={SessionsRouterPaths.SchedulePath}
                element={
                    <Suspense fallback={<RelativeLoading show fixed transparency />}>
                        <SchedulePage />
                    </Suspense>
                }
            />

            {/* SESSIONS EVALUATION */}
            <Route path={SessionsRouterPaths.PendingEvaluation} element={
                <Suspense fallback={<RelativeLoading show fixed transparency />}>
                    <EvaluationPage />
                </Suspense>
            } />
        </Routes>
    );
};