import { useMsal } from "@azure/msal-react";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IMsalAccountsProps {
    className?: string;
}

export const MsalAccounts = styled((props: IMsalAccountsProps) => {
    const { instance, accounts } = useMsal();
    const { t } = useTranslation("topBar");

    const currentAccount = useMemo(() => {
        let account = instance.getActiveAccount();
        if (!account) {
            const all = instance.getAllAccounts();
            instance.setActiveAccount(all[0]);
            account = all[0];
        }
        return account;
    }, [instance, accounts]);

    const [isOpen, setIsOpen] = useState(false);

    const onKeyPressed = (e: KeyboardEvent<HTMLDivElement>, calback: () => void) => {
        if (e.code === "Space" || e.code === "Enter") {
            e.preventDefault();
            calback();
        }
    };

    useEffect(() => {
        const func = (e: any) => {
            const path = e.path || (e.composedPath && e.composedPath());
            const contain = path.findIndex((x: any) => x.id === "msalAccounts");
            if (contain === -1) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', func);
        return () => document.removeEventListener('click', func);
    }, []);

    return (
        <div id={"msalAccounts"} onKeyDown={(e) => onKeyPressed(e, () => setIsOpen(true))} tabIndex={1} onClick={() => setIsOpen(old => true)} className={`${props.className}`}>
            <span>{currentAccount?.name}</span>
            <div data-state={isOpen ? "opened" : "closed"} className={"accountsList"}>
                <div tabIndex={0} onKeyDown={(e) => onKeyPressed(e, () => instance.logout())} onClick={() => instance.logout()} className={"account"}>
                    {t("loggout")}
                </div>
            </div>
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    min-width: 100px;
    position: relative;
    min-height: 64px;
    justify-content: center;
    background: ${props => props.theme.colors.white};
    padding: 20px;
    height: 100%;
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.colors.grey};
    }
    span {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .accountsList {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        z-index: -1;
        background: ${props => props.theme.colors.white};
        box-shadow: 0 6px 30px ${props => props.theme.colors.shadow};
        &[data-state='closed'] {
            box-shadow: 0 0 0;
            transform: translateY(calc(-100% - 5px));
        }
        
        .account {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            svg {
                width: 20px;
                color: ${props => props.theme.colors.secondary};
            }
            &:hover {
                background: ${props => props.theme.colors.greyLight};
            }
        }
    }
`;