import { IColors } from "styled-components";

const light = {
    font: '#000000',
    fontLight: "#ffffff",
    fontDark: "#493224",
    border: "#e9e9e9",
    borderLight: "transparent",
    shadow: "#00000029",
    white: "#ffffff",
    highlighted: "#ffffff",
    black: "#000000",

    primary: '#5F1478',
    primaryLight: '#BA68C8',
    secondary: '#EB5B4C',
    secondaryLight: "#F3903C",
    tertiary: "#B71B6F",
    tertiaryLight: "#B71B6Fa6",
    
    
    grey: `#f6f6f6`,
    greyDark: `#9c9c9c`,
    greyLight: `#fafafa`,
    
    success: "#85c515",
    successLight: "#f4fde5",
    warning: "#f9af00",
    warningLight: "#FFF8E9",
    danger: "#dc3545",
    dangerLight: "#FCD8DD",
}

const newDark = {
    font: '#ffffff',
    fontLight: "#ffffff",
    fontDark: "#493224",
    border: "#ffffff",
    borderLight: "#3F3F3F",
    shadow: "#222222",
    white: "#292929",
    highlighted: "#141414",
    black: "#FFFFFF",

    primary: '#F13098',
    primaryLight: "#AB005C",
    secondary: '#FF4F3D',
    secondaryLight: '#F3903C',
    tertiary: "#FF6DBB",
    tertiaryLight: "#C70B70",

    grey: `#0A0A0A`,
    greyDark: `#ffffff`,
    greyLight: `#252525`,

    success: "#85C515",
    successLight: "#4B7500",
    warning: "#F9AF00",
    warningLight: "#B27900",
    danger: "#DC3545",
    dangerLight: "#8A0013"
}

const newContrast = {
    font: '#ffff01', //
    fontLight: "#ffffff",
    fontDark: "#000000", //Nao mudar idependente do tema
    border: "#afafaf", //
    borderLight: "#626262",
    shadow: "#000000",
    white: "#000000", //
    black: "#000000",
    highlighted: "#000000",

    primary: '#ffff01',
    primaryLight: '#ffff01a6',
    secondary: '#1aebff',
    secondaryLight: "#F3903C",
    tertiary: "#ffff01",
    tertiaryLight: "#ffff01a6",
    

    grey: `#ffffff`,
    greyDark: `#ffffff`,
    greyLight: `#000000`, //#1aebff
    
    success: "#85c515",
    successLight: "#f4fde5",
    warning: "#f9af00",
    warningLight: "#FFF8E9",
    danger: "#dc3545",
    dangerLight: "#8A0013",
}

export const font = {
    family: "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto",
    size: "13px",
    weight: "bold",
}

export default {
    colors: light,
    font
};

export const lightTheme: IColors = light;
export const darkTheme: IColors = newDark;
export const contrastTheme: IColors = newContrast;

