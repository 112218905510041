import { ToastContext } from "@essentials/default-styles";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SpecialistEndpoints } from "../../common/enums/SpecialistEndpoints";
import { AuthenticationContext } from "../../contextAPIs/AuthenticationContext";
import { axiosErrorResponseString } from "../../helpers/functions/axiosErrorResponse";
import { IApiError } from "../../interfaces/base/IApiError";
import { IUser } from "../../interfaces/base/IUser";
import { UserRepository } from "../../repository/base/UserRepository";
import { GeneralService } from "../../services/GeneralService";

export function useUserBusiness() {
    const userContext = useContext(AuthenticationContext);
    const toastContext = useContext(ToastContext);
    const generalService = new GeneralService(SpecialistEndpoints.Specialist, userContext.refreshToken);
    const userRepository = new UserRepository();
    const { t } = useTranslation("general");

    return {
        getUserMe: (idToken?: string): Promise<IUser | undefined> => new Promise((resolve, reject) => {
            generalService._token = idToken ?? generalService._token;
            generalService.GetOnUrl<(IUser | undefined)>(`/me`)
                .then((response) => {
                    resolve(response ?? userRepository.mapObject(response));
                }).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.getError", { entity: t("entities.user") }), err),
                        itemKey: "userError",
                        type: "danger"
                    });
                    reject(err);
                });
        })
    };
};