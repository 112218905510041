import * as React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useWindowSize } from '@essentials/helpers';
import { ThemeProvider } from 'styled-components';
import { AuthenticationContextAPI } from './contextAPIs/AuthenticationContext';
import { AppRouter } from './pagesRouter';
import { RelativeLoading } from './components/RelativeLoading/RelativeLoading';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from '@essentials/default-styles';
import GlobalStyles from './helpers/styles/GlobalStyles';
import { lightTheme, font } from './helpers/styles/theme';


function App() {
  const windowSize = useWindowSize();

  return (
    <ThemeProvider theme={{
      colors: lightTheme,
      font,
      windowSize: {
        ...windowSize,
        mobileLimit: 720,
        isMobile: windowSize.width <= 651,
      }
    }}>
      <GlobalStyles />
      <BrowserRouter basename="">
        <ToastProvider>
          <AuthenticationContextAPI>
            <AuthenticatedTemplate>
              <AppRouter />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <RelativeLoading show fixed />
            </UnauthenticatedTemplate>
          </AuthenticationContextAPI>
        </ToastProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
