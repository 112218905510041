import { IRepository } from "../../interfaces/base/IRepository";
import { IUser } from "../../interfaces/base/IUser";
import { AuditableEntitiesRepository } from "./AuditableEntitiesRepository";

export class UserRepository implements IRepository<IUser>{
    private objectSet(item: any): IUser {
        const auditableRepository = new AuditableEntitiesRepository();
        const auditableEntity = auditableRepository.mapObject(item);

        return {
            name: item.name ?? undefined,
            mail: item.mail ?? undefined,
            pictureUrl: item.pictureUrl ?? undefined,
            domain: item.domain ?? undefined,
            ...auditableEntity
        };
    }

    mapObject(item: any): (IUser | undefined) {
        return item ? this.objectSet(item) : undefined;
    }

    mapObjects(items: any[]): IUser[] {
        return items?.length ? items.map(this.objectSet) : [];
    }
};