import { Button, FilledButton, Modal } from "@essentials/default-styles";
import { CloseIcon } from "@essentials/icons";
import { useState } from "react";
import styled from "styled-components";

export interface ICustomCalendarModal {
    isModalOpen: boolean;
    startDate: Date;
    endDate: Date;
    className?: string;
    savingCalendars?: boolean;
    onCloseModal: () => void;
    onSave: (initialDate: Date, endDate: Date, repeatType: number, numberOfWeeks: number) => void;
}


export const CustomCalendarModal = styled(function (props: ICustomCalendarModal) {
    const [repeatType, setRepeatType] = useState(0);
    const [numberOfWeeks, setNumberOfWeeks] = useState(4);

    function addMinutes(date: Date, minutes: number): Date {
        let newDate = new Date(date.getTime()); // Cria uma nova instância de Date
        newDate.setMinutes(newDate.getMinutes() + minutes);
        return newDate;
    }

    function formatDate(date: Date): string {
        const userLocale = navigator.language;
        
        let options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        let formattedDate = date.toLocaleString(userLocale, options);
    
        const isPortuguese = userLocale.startsWith('pt');
        if (isPortuguese) {
            formattedDate = formattedDate.replace(',', ' às');
        }
        else {
            formattedDate = formattedDate.replace(',', ' ');
        }
        return formattedDate;
    }

    function areDatesOnSameDay(date1: Date, date2: Date): boolean {
        return date1.getFullYear() === date2.getFullYear() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getDate() === date2.getDate();
    }

    return (
        <Modal open={props.isModalOpen} className={`${props.className}`} style={{height: 280}}>
            <div className={`innerModal`}>
                <div className={`header`}>
                    <button onClick={() => {
                        props.onCloseModal();
                    }}
                        className={"closeBtn"}>
                        <CloseIcon />
                    </button>
                </div>

                <div className={`content`}>
                    <h2>Confirmação de disponibilidade</h2>
                    <p>
                        De <b>{formatDate(props.startDate)}</b> até <b>{formatDate(addMinutes(props.endDate, 30))}</b>
                    </p>

                    <span>Repetir &nbsp;</span>
                    <select value={repeatType} onChange={e => setRepeatType(parseInt(e.target.value))}>
                        <option value="0">Não repetir</option>
                        {areDatesOnSameDay(props.startDate, addMinutes(props.endDate, 30)) && <option value="1">Diariamente</option>}
                        <option value="2">Semanalmente</option>
                    </select>
                    {repeatType !== 0 && <span> por <input type="number" value={numberOfWeeks} onChange={e => setNumberOfWeeks(parseInt(e.target.value))} /> semanas</span>}
                    <FilledButton loading={props.savingCalendars} onClick={() => props.onSave(props.startDate, addMinutes(props.endDate, 30), repeatType, numberOfWeeks)}>Adicionar disponibilidade</FilledButton>
                </div>
            </div>
        </Modal>
    )
})`
    .innerModal {
        .header {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;

            button {
                border: none;
                background: none;
                color: gray;
                margin: 10px;
                cursor: pointer;
            }
        }

        .content {
            padding: 0px 20px 20px 20px;

            h1,h2,h3 {
                margin: 0px;
                color: black;
            }

            p {
                margin: 0px;
                margin-bottom: 15px;
            }

            select {
                padding: 3px 10px;
                border: 1px solid darkgray;
                border-radius: 4px;
                margin-bottom: 30px;
                color: #2e2e2e;
            }

            input {
                padding: 0px 10px;
                border: 1px solid darkgray;
                border-radius: 8px;
                margin-bottom: 10px;
                color: #2e2e2e;
                width: 60px;
                text-align: center;
                font-weight: bold;
            }

            button.reserve {
                width: calc(100% - 40px);
                background: #762478;
                color: white;
                font-weight: bold;
                border: none;
                padding: 10px;
                border-radius: 8px;
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translate(-50%);
                cursor: pointer;
            }
        }
    }
`;