import { isValid } from "date-fns";
import { normalizeTimezone } from "@essentials/helpers";
import { IRepository } from "../../interfaces/base/IRepository";
import { IAuditableEntities } from "../../interfaces/base/IAuditableEntities";

export class AuditableEntitiesRepository implements IRepository<IAuditableEntities>{
    private objectSet(item: any): IAuditableEntities {
        return {
            id: item.id ?? undefined,
            createdAt: item.createdAt && isValid(new Date(item.createdAt)) ? normalizeTimezone(new Date(item.createdAt)) : undefined,
            lastModified: item.lastModified && isValid(new Date(item.lastModified)) ? normalizeTimezone(new Date(item.lastModified)) : undefined,
        };
    }

    mapObject(item: any): (IAuditableEntities | undefined) {
        return item ? this.objectSet(item) : undefined;
    }

    mapObjects(items: any[]): IAuditableEntities[] {
        return items?.length ? items.map(this.objectSet) : [];
    }
};