export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_CLIENT_ID}`, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/common`,
        redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    account: undefined,
    scopes: [`api://${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_CLIENT_ID}/access_as_user`]
};

export const protectedResources = {
    helpfySpecialistAPI: `${process.env.REACT_APP_API}/specialists`
}
