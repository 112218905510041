import { ToastContext } from "@essentials/default-styles";
import { AxiosError } from "axios";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SpecialistEndpoints } from "../../common/enums/SpecialistEndpoints";
import { AuthenticationContext } from "../../contextAPIs/AuthenticationContext";
import { axiosErrorResponseString } from "../../helpers/functions/axiosErrorResponse";
import { ICalendar } from "../../interfaces/app/ICalendar";
import { IApiError } from "../../interfaces/base/IApiError";
import { CalendarRepository } from "../../repository/app/CalendarRepository";
import { GeneralService } from "../../services/GeneralService";

export function useCalendarBusiness() {
    const userContext = useContext(AuthenticationContext);
    const toastContext = useContext(ToastContext);
    const generalService = new GeneralService(SpecialistEndpoints.Calendars, userContext.refreshToken);
    const calendarRepository = new CalendarRepository();
    const { t } = useTranslation("general");

    return {
        getMyCalendars: (): Promise<ICalendar[]> => new Promise((resolve, reject) => {
            generalService.GetOnUrl<ICalendar[]>(``)
                .then((response) => {
                    resolve(calendarRepository.mapObjects(response));
                }).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.getError", { entity: t("entities.calendars") }), err),
                        itemKey: "calendarsError",
                        type: "danger"
                    });
                    reject(err);
                });
        }),

        addCalendar: (dateString: string): Promise<void> => new Promise((resolve, reject) => {
            generalService.PostOnUrl<void>(`?availableAt=${dateString}`)
                .then(() => resolve()).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.saveError", { entity: t("entities.calendar") }), err),
                        itemKey: "calendarsError",
                        type: "danger"
                    });
                    reject(err);
                });
        }),

        addMultipleCalendars: (initialDate: Date, endDate: Date, repeatType: number, numberOfWeeks: number): Promise<void> => new Promise((resolve, reject) => {
            generalService.PostOnUrl<void>("/batch", {
                initialDate,
                endDate,
                repeatType,
                numberOfWeeks
            }).then(() => resolve()).catch((err: AxiosError<IApiError>) => {
                toastContext.addToast({
                    title: axiosErrorResponseString(t("errors.saveError", { entity: t("entities.calendars") }), err),
                    itemKey: "calendarsError",
                    type: "danger"
                });
                reject(err);
            });
        }),

        removeCalendar: (calendarId: number, date: Date): Promise<void> => new Promise((resolve, reject) => {
            generalService.PostOnUrl(`/delete`, {
                calendarId,
                initialDate: date
            })
                .then(() => resolve()).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.deleteError", { entity: t("entities.calendar") }), err),
                        itemKey: "calendarsError",
                        type: "danger"
                    });
                    reject(err);
                });
        }),

        clearCalendars: (): Promise<void> => new Promise((resolve, reject) => {
            generalService.PostOnUrl(`/clear`)
                .then(() => resolve()).catch((err: AxiosError<IApiError>) => {
                    toastContext.addToast({
                        title: axiosErrorResponseString(t("errors.deleteError", { entity: t("entities.calendar") }), err),
                        itemKey: "calendarsError",
                        type: "danger"
                    });
                    reject(err);
                });
        })
    };
};